.account {
  .account-actions {
    text-align: right;
  }
  .account-form {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;

    .account-input {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
