.homepage {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(4, auto);
  column-gap: 1rem;

  .top-bar {
    grid-column: 1;
    grid-row: 1;
    text-align: right;
    padding: 1rem;

    div {
      display: inline-block;
    }
  }

  nav {
    grid-column: 1;
    grid-row: 2;
  }

  .content-wrapper {
    background-image: url(../../assets/background-werk2.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding: 1rem;
    grid-column: 1;
    grid-row: 3;
  }

  .homepage-footer {
    grid-row: 4;
    grid-column: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1rem;

    & > a {
      width: 100%;
      padding: 0.5rem;
    }
  }
}

@media screen and (min-width: 37.5rem) {
  .homepage {
    height: 100vh;
    grid-template-columns: 14rem auto;
    grid-template-rows: 4rem auto 3rem;

    nav {
      grid-column: 1;
      grid-row: span 2;
    }

    .top-bar {
      grid-column: 2;
      grid-row: 1;
    }

    .content-wrapper {
      grid-column: 2;
      grid-row: 2;
    }

    .homepage-footer {
      grid-row: 3;
      grid-column: span 2;

      & > a {
        width: initial;
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .homepage .content-wrapper {
    background-attachment: initial;
  }
}
