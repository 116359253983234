.navigation-vertical {
  color: #eee;

  .navigation-small {
    max-width: 100vw;
  }

  .tab span {
    text-align: left;
    align-items: initial;
  }

  .navigation-icon {
    margin-right: 0.5rem;

    svg {
      vertical-align: middle;
    }
  }
}
