.loginreset {
  .loginreset-actions {
    text-align: right;
  }
  .loginreset-form {
    width: 100%;
    max-width: 40rem;
    margin: 5rem auto 0;

    .loginreset-input {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
