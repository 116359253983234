.stream-title {
  margin: 1.5rem 0 1rem;
  color: #eee;
}

.single-stream {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  aside,
  .article-details {
    margin: 0;
  }

  .article-details {
    padding: 1rem;
  }

  h3:first-of-type {
    margin-top: 0;
  }

  aside {
    background: rgba(0, 0, 0, 0.25);
    border-right: 1px solid rgba(50, 50, 50, 0.5);

    img {
      width: 100%;
      margin-bottom: -0.25rem;
      border-radius: 0.25rem 0 0 0;
    }
  }

  .single-stream-actions {
    display: inline-block;
    width: 100%;
    text-align: center;

    & > * {
      min-width: 90%;
    }

    & > *:first-child {
      margin-top: 1rem;
    }

    & > *:last-child {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 37.5rem) {
    grid-template-columns: 18rem auto;

    aside {
      grid-column: 1;
      grid-row: 1;

      img {
        margin-left: 0;
      }
    }

    .article-details {
      grid-column: 2;
      grid-row: 1;
    }
  }
}
