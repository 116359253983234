.summary {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(4, auto);
  grid-gap: 2rem;

  .summary-header {
    grid-row: 1;
    grid-column: 1;
  }

  .summary-headerimage {
    margin: 0 auto;
    display: block;
    max-width: 20rem;
  }

  .summary-article {
    grid-row: 2;
    grid-column: 1;
  }

  .summary-table {
    width: 100%;

    .summary-table-amount,
    .summary-table-item,
    .summary-table-price {
      padding: 0.5rem;
    }

    .summary-table-amount,
    .summary-table-item-total {
      text-align: right;
    }

    thead {
      .summary-table-amount,
      .summary-table-item,
      .summary-table-price {
        font-weight: bold;
      }
    }

    tfoot {
      font-weight: bold;
      color: #eee;

      .summary-table-item,
      .summary-table-price {
        padding-top: 2rem;
      }

      .summary-table-price {
        border-bottom: 3px double #eee;
      }
    }
  }

  .summary-personaldata {
    grid-row: 3;
    grid-column: 1;
  }

  .summary-footer {
    grid-row: 4;
    grid-column: 1;
    padding-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
  }

  .summary-terms {
    width: 100%;
  }
}

@media screen and (min-width: 37.5rem) {
  // this is a trump and this must be !important.

  .summary {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);

    .summary-header {
      grid-row: 1;
      grid-column: span 2;
    }

    .summary-headerimage {
      max-width: 90%;
    }

    .summary-article {
      grid-row: 2;
      grid-column: 2;
    }

    .summary-personaldata {
      grid-row: 2;
      grid-column: 1;
    }

    .summary-footer {
      grid-row: 3;
      grid-column: span 2;
    }
  }
}
