.parallax {
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;

  div {
    text-align: center;
    width: 100%;
  }

  &--plain {
    display: initial;
    align-items: initial;
    justify-content: initial;

    div {
      text-align: left;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .parallax {
    background-attachment: initial;
  }
}
