.payment {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(4, auto);
  grid-gap: 2rem;
  position: relative;

  .MuiFormControl-marginNormal {
    margin-top: 0;
  }

  .payment-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34, 34, 34, 0.9);
    font-size: 2rem;
    display: none;
    align-items: center;
    justify-content: center;
    // Paypal sucks...
    z-index: 101;

    &.transaction {
      display: flex;
    }

    .payment-overlay-content {
      text-align: center;
    }
  }

  .payment-header {
    grid-row: 1;
    grid-column: 1;
  }

  .payment-headerimage {
    margin: 0 auto;
    display: block;
    max-width: 20rem;
  }

  .payment-article {
    grid-row: 2;
    grid-column: 1;
  }

  .payment-table {
    width: 100%;

    .payment-table-amount,
    .payment-table-item,
    .payment-table-price {
      padding: 0.5rem;
    }

    .payment-table-amount,
    .payment-table-item-total {
      text-align: right;
    }

    thead {
      .payment-table-amount,
      .payment-table-item,
      .payment-table-price {
        font-weight: bold;
      }

      .payment-table-price {
        min-width: 3rem;
      }
    }

    tfoot {
      font-weight: bold;
      color: #eee;

      .payment-table-item,
      .payment-table-price {
        padding-top: 2rem;
      }

      .payment-table-price {
        border-bottom: 3px double #eee;
      }
    }
  }

  .payment-personaldata {
    grid-row: 3;
    grid-column: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto repeat(4, 4rem) auto;
    gap: 1rem;

    .payment-description {
      grid-row: 1;
      grid-column: span 4;
    }

    .payment-firstname {
      grid-row: 2;
      grid-column: span 2;
    }
    .payment-lastname {
      grid-row: 2;
      grid-column: span 2;
    }

    .payment-street {
      grid-row: 3;
      grid-column: span 3;
    }

    .payment-streetno {
      grid-row: 3;
      grid-column: 4;
    }

    .payment-postalcode {
      grid-row: 4;
      grid-column: span 2;
    }

    .payment-city {
      grid-row: 4;
      grid-column: span 2;
    }

    .payment-birthday {
      grid-row: 5;
      grid-column: span 2;
    }

    .payment-country {
      grid-row: 5;
      grid-column: span 2;
    }

    .payment-information {
      grid-row: 6;
      grid-column: span 4;
    }
  }

  .carticle {
    margin-top: 2rem;

    h3 {
      margin-top: 0;
    }
  }

  .payment-custom-donation {
    display: flex;
    align-items: center;

    .payment-custom-donation-amount {
      margin-left: 1rem;
      width: 5rem;
    }
  }

  .payment-footer {
    grid-row: 4;
    grid-column: 1;

    .payment-footer-terms {
      margin-bottom: 1rem;
    }
  }
}

@media screen and (min-width: 37.5rem) {
  // this is a trump and this must be !important.

  .payment {
    grid-template-columns: repeat(2, calc(50% - 1rem));
    grid-template-rows: repeat(3, auto);

    .payment-header {
      grid-row: 1;
      grid-column: span 4;
      width: calc(100% - 2rem);
    }

    .payment-headerimage {
      max-width: 90%;
    }

    .payment-article {
      grid-row: 2;
      grid-column: 2;
    }

    .payment-personaldata {
      grid-row: 2;
      grid-column: 1;
    }

    .payment-footer {
      grid-row: 3;
      grid-column: span 2;
      display: grid;
      grid-template-rows: 1;
      grid-template-columns: repeat(4, 1fr);

      .payment-footer-terms {
        grid-row: 1;
        grid-column: span 3;
        margin-bottom: 0;
      }

      .paypal-buttons {
        grid-row: 1;
        grid-column: 4;
      }
    }
  }
}
