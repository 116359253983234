.linked-logo {
  display: block;
  height: 6.4375rem;
  margin: 1rem;

  img {
    width: 100%;
    max-height: 100%;
    max-width: 80vw;
  }
}
