.about {
  .about-partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  // .about-coverletter {
  //   width: 50%;
  //   margin: 0 auto;
  // }

  .about-partner {
    width: 33%;
    background: rgba(255, 255, 255, 1);
    padding: 0.75rem;
    border-radius: 0.5rem;
  }

  .about-header {
    margin-top: 3rem;
  }

  .about-pre {
    font-family: monospace;
    font-size: 0.8rem;
  }

  cite {
    display: block;
    margin-left: 1.5rem;

    &:before {
      content: '"';
      font-size: 3rem;
      float: left;
      font-family: cursive;
      margin-left: -2rem;
      margin-top: -1rem;
      color: #888;
    }
  }
}
