.player {
  height: initial;
  margin: 0 auto;
  background: #111;
  padding: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto) 20rem;

  &--solo {
    max-width: 80rem;
    min-width: 60vw;
  }

  &--nochat {
    grid-template-rows: repeat(3, auto);
  }

  .vjs-big-play-centered:hover .vjs-big-play-button {
    background-color: #444;
  }

  .vjs-control-bar {
    background: #222;
  }

  .vjs-big-play-button {
    border: none;
    background: #222;
    font-size: 2.5rem;

    &:hover,
    &:focus {
      background-color: #444;
    }
  }

  .video-wrapper {
    grid-column: 1;
    grid-row: 1;
    background: #000;
  }

  .video-player {
    height: initial;
    width: 100%;
    padding-top: 56.25%;
  }

  .video-chat {
    position: relative;
  }

  .video-description,
  .video-aside {
    padding: 1rem;
  }

  .video-description {
    grid-column: 1;
    grid-row: 2;
  }

  .video-aside {
    grid-column: 1;
    grid-row: 3;
  }
  h2 {
    margin: 0;
  }

  h3 {
    margin: 0.125rem 0 0 0;
    font-size: 0.875rem;
    font-weight: 100;
  }
}

@media screen and (min-width: 37.5rem) {
  .player {
    grid-template-rows: auto 5rem auto;
    grid-template-columns: 3fr 1fr;

    &--nochat .video-wrapper {
      grid-column: span 2;
    }

    .video-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .video-chat {
      grid-row: 1;
      grid-column: 2;
    }

    &--chathidden {
      grid-template-rows: 3rem auto 5rem auto;

      .video-chat {
        grid-row: 1;
        grid-column: span 2;
      }

      .video-wrapper {
        grid-row: 2;
        grid-column: span 2;
      }

      .video-description {
        grid-row: 3;
      }
      .video-aside {
        grid-row: 4;
      }
    }
  }
}
