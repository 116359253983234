.faq {
  .player {
    border-radius: 0.25rem;
    margin: 1rem auto;
  }

  .details {
    display: block;
  }
}
