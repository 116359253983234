.register-dialog {
  .register-input {
    display: block;
    margin-top: 1rem;

    div {
      width: 100%;
    }
  }
}
