body {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #9a9a9a;
  background-color: #111;
  margin: 0;
}

a:not(.MuiButtonBase-root),
a:hover:not(.MuiButtonBase-root),
a:active:not(.MuiButtonBase-root) {
  color: #f50057;
  text-decoration: none;
  transition: 0.25s;
}

h1 {
  margin-top: 0;
}

.carticle {
  background: rgba(34, 34, 34, 1);
  border-radius: 0.25rem;
  margin: 0 0 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.3rem rgba(10, 10, 10, 0.9);
}

.carticle--lighten {
  background: rgba(127, 127, 127, 0.1);
}

.carticle--padded {
  padding: 1rem;
}

// this is a trump and this must be !important.
.hide-small {
  display: none !important;
}

.paper {
  padding: 1rem;
}

.text-centered {
  text-align: center;
}

@media screen and (min-width: 37.5rem) {
  // this is a trump and this must be !important.
  .hide-small {
    display: initial !important;
  }

  // this is a trump and this must be !important.
  .show-small {
    display: none !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  article {
    background: rgba(34, 34, 34, 1);
  }
}
