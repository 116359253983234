.contact {
  .contact-form {
    margin-top: 5rem;
    justify-content: center;

    & > * {
      width: 100%;
      max-width: 40rem;
      justify-content: left;
    }

    .contact-input {
      margin-top: 1rem;
    }
  }
}
