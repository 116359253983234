.marketing {
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: repeat(3, auto);
  color: #ccc;
  font-size: 1.1rem;
  line-height: 1.5rem;

  article {
    box-shadow: none;
  }

  .caption-h1,
  .caption-h2,
  .caption-h3,
  .caption-h4,
  .caption-h5,
  .caption-h6 {
    color: #eee;
    font-weight: normal;
  }

  .caption-h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  .caption-h2 {
    font-size: 1.5rem;
  }

  .caption-h3 {
    font-size: 1.25rem;
  }

  nav {
    grid-row: 1;
    grid-column: 1;
    text-align: right;
  }

  main {
    grid-row: 2;
    grid-column: 1;

    .description {
      padding: 0 1rem;
      margin: 2rem auto;
    }
  }

  footer {
    grid-row: 3;
    grid-column: 1;
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    & > a {
      padding: 0.5rem;
      width: 100%;
    }
  }
}

@media screen and (min-width: 37.5rem) {
  .marketing {
    grid-template-rows: 3rem auto 4rem;

    .caption-h1 {
      font-size: 3rem;
      font-weight: bold;
    }

    .caption-h2 {
      font-size: 2rem;
    }

    .caption-h3 {
      font-size: 1.5rem;
    }

    nav {
      line-height: 3rem;
      margin-right: 1rem;
    }

    main {
      grid-row: 2;
      grid-column: 1;

      .description {
        max-width: 80rem;
        margin: 2rem auto;
      }
    }

    footer > a {
      width: initial;
    }
  }
}
